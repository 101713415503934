import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import SetViewportHeight from "./utils/SetViewportHeight";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<>
    <SetViewportHeight />
    <App />
</>);
