import { useSocket } from "Providers/SocketProvider";
import Toast from "components/common/Toast";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import store from "store";
import {
	Message,
	Thread,
	addNewMessage,
	setUserOnlineStatus,
	updateCallsForThread,
} from "store/reducers/messageReducer";
import API from "../utils/axios";
import useAuthHook from "./useAuthHook";
import useUserHook from "./useUserHook";

type Props = {
	onMessageReceive?: (message: any) => void;
};

const useChat = ({ onMessageReceive }: Props) => {
	const { socket } = useSocket();
	const { user } = useAuthHook();
	const { getCurrentUser } = useUserHook();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!socket) {
			return;
		}
		console.log("outside socket");
		socket.on("message-received", (message: any) => {
			const thread = store.getState().messages?.threads?.find((thread) => thread.id === message.clientId);
			const isSnoozed =
				thread?.chat?.snoozeReminder?.[0] && thread.chat.snoozeReminder[0].snoozeUntil >= new Date().toISOString();
			const isActiveThread = store.getState().messages.currentThread?.id === message.clientId;

			if (!isSnoozed) {
				const audio = document?.getElementById("message-ring-audio") as HTMLAudioElement;

				var playPromise = audio.play();

				if (playPromise !== undefined) {
					playPromise
						.then((_) => {
							// Automatic playback started!
							// Show playing UI.
						})
						.catch((error) => {
							// Auto-play was prevented
							// Show paused UI.
						});
				}
			}
			const { clientId, userId, message: content, images, client, chatId } = message;
			const mes: Message = {
				id: null,
				content,
				senderType: "client",
				type: "text",
				senderId: userId,
				images,
				sentAt: new Date().toString(),
				createdAt: new Date().toString(),
			};
			dispatch(addNewMessage({ message: mes, clientId, client, chatId }));
			onMessageReceive?.(message);
		});

		socket.on("sent-message-created", (message: any) => {
			console.log("sent-message-created", message);
		});
		socket.on("sent-call-ringing", (call: any) => {
			Toast.info("Call Ringing!");
		});
		socket.on("sent-call-answered", (call: any) => {
			Toast.success("Call answered!");
		});
		socket.on("recording-in-progress", (call) => {
			Toast.info(`${call.client.name} is leaving a message...`);
		});
		socket.on("sent-call-no-answer", (call) => {
			Toast.info("Call was not answered or rejected");
		});
		socket.on("recording-completed", (recording) => {
			Toast.success("Voicemail received!");
			const currentThread = store.getState().messages.currentThread;
			console.log("Current thread");
			console.log(currentThread);
			if (currentThread?.id) {
				API.get(`calls/${currentThread?.id}?page=${1}&pageSize=${Number.MAX_SAFE_INTEGER}`).then((res) => {
					dispatch(updateCallsForThread({ clientId: currentThread.id, calls: res.data.data }));
				});
			}
		});
		socket.on("recording-failed", (recording) => {
			Toast.warn("Recording Failed!");
		});
		socket.on("call-received", (call: any) => {
			const audio = document?.getElementById("call-ring-audio") as HTMLAudioElement;

			// var playPromise = audio.play();

			// if (playPromise !== undefined) {
			// 	playPromise
			// 		.then((_) => {
			// 			// Automatic playback started!
			// 			// Show playing UI.
			// 		})
			// 		.catch((error) => {
			// 			// Auto-play was prevented
			// 			// Show paused UI.
			// 		});
			// }
			console.log("call-received", call);
			Toast.info(`${call.client.name} is calling!`);
			const currentThread = store.getState().messages.currentThread;
			if (currentThread?.id) {
				API.get(`calls/${currentThread?.id}?page=${1}&pageSize=${Number.MAX_SAFE_INTEGER}`).then((res) => {
					dispatch(updateCallsForThread({ clientId: currentThread.id, calls: res.data.data }));
				});
			}
		});

		socket.on("sent-call-failed", (call: any) => {
			Toast.warn("Call failed to deliver!");
		});

		socket.on("sent-call-completed", (call: any) => {
			Toast.success("Call Completed");
			setTimeout(async () => {
				const { clientId, chatId, userId } = call;

				try {//TODO fix this pagesize to something more reasonable in a few places
					API.get(`calls/${clientId}?page=${1}&pageSize=${Number.MAX_SAFE_INTEGER}`).then((res) => {
						dispatch(updateCallsForThread({ clientId: clientId, calls: res.data.data }));
					});
				} catch (error) {
					console.error("Error fetching calls:", error);
				}
			}, 4000);
		});

		socket.on("default-reply", (message: any) => {
			const { clientId, userId, defaultReply: content, images, client, chatId } = message;
			console.log("default message", message);
			const mes: Message = {
				id: null,
				content,
				senderType: "user",
				type: "text",
				senderId: userId,
				images,
				sentAt: new Date().toString(),
				createdAt: new Date().toString(),
			};
			dispatch(addNewMessage({ message: mes, clientId, client, chatId }));
			onMessageReceive?.(message);
		});

		socket.on("sent-message-sent", (message: any) => {
			console.log("sent-message-sent", message);
		});

		socket.on("no-number", (message: any) => {
			getCurrentUser();
		});

		socket.on("active-users", (users: any) => {
			console.log("sent-message-sent", users);
			dispatch(setUserOnlineStatus({ ids: users }));
		});

		return () => {
			socket.removeAllListeners();
		};
	}, [socket]);

	const sendMessage = async (thread: Thread, message?: string, image?: any, audio?: Blob) => {
		if (socket) {
			const twillioMessage = {
				userId: user?.id,
				...(message && { message }),
				...(thread?.chat?.id && { chatId: thread?.chat?.id }),
				clientId: thread.id,
				...(image && { file: image }),
				...(audio && { file: audio }),
			};
			let imgSrc = null;
			let audioSrc = null;
			if (image) {
				//@ts-ignore
				// const res = await dispatch(sendImage(twillioMessage))
				API.postForm(`/user/send-image`, twillioMessage);
				imgSrc = URL.createObjectURL(image);
			}
			if (audio) {
				API.postForm(`/user/send-audio`, twillioMessage);
				audioSrc = URL.createObjectURL(audio);
			} else {
			}

			const mes: Message = {
				id: null,
				...(message && { content: message }),
				senderType: "user",
				type: "text",
				senderId: user?.id as string,
				...(image && { images: [{ link: imgSrc }] }),
				...(audio && { audios: [{ link: audioSrc }] }),
				sentAt: new Date().toString(),
			};
			console.log({ mes, audio, audioSrc });
			dispatch(addNewMessage({ message: mes, clientId: thread.id }));

			if (!image && !audio) {
				socket.emit("message-send", twillioMessage);
			}
		}
	};

	return { sendMessage };
};

export default useChat;
