export const getDateUntil9AM = (days: number): Date => {
    // Get current date
    let currentDate: Date = new Date();
    // Add days to current date
    currentDate.setDate(currentDate.getDate() + days);
    // Set time to 9 AM
    currentDate.setHours(9, 0, 0, 0);
    // Check if the resulting date is after the current time
    // If not, advance it to the next day
    if (currentDate <= new Date()) {
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate;
}

export const convertUTCToLocaleString = (utc: string) => {
    const utcDate = new Date(utc);
    return utcDate.toLocaleString();
}
