import useAuthHook from "hooks/useAuthHook";
import React, { ReactNode } from "react";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";

export const CommonLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { user } = useAuthHook();

	const plan = getPaymentPlan(user);
	console.log({ plan });
	const isTrial = plan === PaymentStatus.TRIAL;

	// useEffect(() => {
	// 	if (isTrial) {
	// 		const appBar = document.createElement("div");
	// 		appBar.className = "app-bar";
	// 		appBar.textContent = "Your App Name"; // Customize this text as needed

	// 		// Find the root div
	// 		const rootDiv = document.getElementById("root");

	// 		// Add the app bar to the root div
	// 		rootDiv?.insertBefore(appBar, rootDiv.firstChild);

	// 		// Create content area (this is just an example)
	// 		const content = document.createElement("div");
	// 		content.className = "content";

	// 		// Append content to the root div
	// 		rootDiv?.appendChild(content);
	// 	}
	// }, [isTrial]);

	return (
		<>
			{/* {isTrial && (
				<div className="bg-yellow-200 text-yellow-800 p-4 fixed top-0 left-0 right-0 z-50">
					Your product is in 30 days trial mode
				</div>
			)} */}

			{children}
		</>
	);
};
