import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { yupResolver } from "@hookform/resolvers/yup";
import Breadcrumb from "components/Breadcrumb";
import CommonTextInput from "components/common/CommonTextInput";
import Toast from "components/common/Toast";
import AuthPage from "hocs/ProtectedRoute";
import useAuthHook from "hooks/useAuthHook";
import Sidebar from "layout/sidebar";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdFileCopy } from "react-icons/md";
import { formattedNumber } from "utils/formattedNumber";
import { ProfileValidationSchema } from "utils/schema";
interface Person {
	id: number;
	name: string;
}
const Profile = () => {
	const { user } = useAuthHook();
	const [uploadFile, setUploadFile] = useState<File | null>();
	const [selected, setSelected] = useState("");
	const [query, setQuery] = useState<string>("");

	const [people] = useState<Person[]>([{ id: 1, name: "Choose another provider" }]);

	const method = useForm({
		mode: "all",
		resolver: yupResolver(ProfileValidationSchema),
		shouldFocusError: false,
	});
	const { handleSubmit, control, reset, setValue } = method;
	const { signUp, isLoading, updateUser } = useAuthHook();

	document.title = "Skip the Call | Profile";
	const filteredPeople =
		query === ""
			? people
			: people.filter((person) =>
				person.name.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")),
			);
	useEffect(() => {
		setValue("firstName", user?.firstName || "");
		setValue("lastName", user?.lastName || "");
		setValue("email", user?.email || "");
		setValue("businessName", user?.account?.businessName || "");
		setValue("mobile", user?.mobile || "");
		setValue("autoReplyText", user?.account?.defaultAutoReplyText || "");
		setValue("referralLink", user?.account?.referralLink || "");
		setValue("businessLogoUrl", user?.account?.businessLogoUrl || "");
	}, [user]);

	const handleRegister = async (data: any) => {
		const body = {
			business_name: data.businessName,
			firstName: data.firstName,
			lastName: data.lastName,
			businessName: data.businessName,
			countryCode: 1,
			mobile: data.mobile,
			referralCode: data.referralLink,
			defaultAutoReplyText: data.autoReplyText,
			"profile-pic": uploadFile,
		};
		await updateUser(body);
	};
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		if (user?.account?.referralLink) {
			navigator.clipboard.writeText(user?.account?.referralLink);
			Toast.success("Referral link copied to clipboard");
			setCopied(true);
		} else {
			Toast.error("No referral link found");
			setCopied(false);
		}
	};
	const formatTwilioNumber = formattedNumber(user?.twilioNumber?.[0].number);
	return (
		<div className="xlg:flex">
			<Sidebar />
			<div className="xlg:ml-[75px] xlg:pt-0 pt-14 xlg:w-[calc(100%-75px)] w-full h-screen">
				<Breadcrumb uploadFile onFileSelected={setUploadFile} />
				<div className="max-w-5xl mx-auto w-full xsm:p-6 p-4">
					{user?.twilioNumber?.[0].number ? (
						<div>
							<p className="text-2xl text-black/80 mt-0.5">
								Your dedicated skipthecall number is <b>{formatTwilioNumber}</b>
							</p>
							<p className="text-base text-dark-gray/80 mt-0.5">
								Still need to set up call forwarding? We've got you covered, just pick your phone provider for
								instructions.
								<div className="items-center mt-6 mb-12 gap-2 grid grid-cols-4 w-full md:w-3/4 xl:w-3/4">
									<a
										className={`border-2 border-gray-300 text-2xl font-small p-1 rounded-sm flex items-center justify-center hover:border-blue-400 ${selected === "verizon" && "bg-zinc-300"
											}`}
										href="https://www.verizon.com/support/knowledge-base-181139/"
										target="_blank"
										onClick={() => setSelected("verizon")}
									>
										<img
											className="lg:h-9 lg:w-20 md:h-8 md:w-20 sm:h-6 sm:w-16 object-contain"
											alt="Verizon"
											src={
												"https://s3.amazonaws.com/cms.ipressroom.com/354/files/20222/60f592e4b3aed30fb2e8a8b4_1_Brand+Logos/1_Brand+Logos_e86a51d3-11b7-42e1-b2bd-cdad912e132d-prv.jpg"
											}
										></img>
									</a>
									<a
										className={`border-2 border-gray-300 text-2xl font-normal p-1 rounded-sm flex items-center justify-center hover:border-blue-400 ${selected === "sprint" && "bg-zinc-300"
											}`}
										href="https://www.ringsavvy.com/tutorials/how-to-set-up-call-forwarding-from-sprint-cell-phone/"
										target="_blank"
										onClick={() => setSelected("sprint")}
									>
										<img
											className="lg:h-9 lg:w-20 md:h-8 md:w-20 sm:h-6 sm:w-16 object-contain"
											alt="Sprint"
											src={"https://upload.wikimedia.org/wikipedia/commons/6/6f/Sprint_Corporation_Logo.svg"}
										></img>
									</a>
									<a
										className={`border-2 border-gray-300 text-2xl font-normal p-1/2 rounded-sm flex items-center justify-center hover:border-blue-400 md:p-1/4  ${selected === "t-mobile" && "bg-zinc-300"
											}`}
										href="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.t-mobile.com/support/tutorials/topic/calling-amp-contacts/forward-calls/device/samsung/galaxy-note-t879&ved=2ahUKEwi6p5Dyj8qFAxUzXvEDHYDeAiIQFnoECDMQAQ&usg=AOvVaw0GsNbgR3jkwoYbDybe98m0"
										target="_blank"
										onClick={() => setSelected("t-mobile")}
									>
										<img
											className="lg:h-11 lg:w-20 md:h-10 md:w-20 sm:h-8 sm:w-16 object-contain"
											alt="T-Mobile"
											src={
												"https://www.t-mobile.com/news/_admin/uploads/2020/06/T-Mobile_New_Logo_Primary_RGB_M-on-W.jpg"
											}
										></img>
									</a>
									<div className="w-54">
										<Combobox value={selected} onChange={setSelected}>
											<div className="relative mt-1">
												<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
													<Combobox.Input
														className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
														displayValue={(person: Person) => person.name}
														onChange={(event) => setQuery(event.target.value)}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
													afterLeave={() => setQuery("")}
												>
													<Combobox.Options
														placeholder="Choose another provider"
														className="absolute mt-1 max-h-60 w-[150%] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
													>
														{filteredPeople.length === 0 && query === "" ? (
															<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
																Choose another provider
															</div>
														) : filteredPeople.length === 0 && query !== "" ? (
															<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
																Nothing found.
															</div>
														) : (
															filteredPeople.map((person) => (
																<Combobox.Option
																	key={person.id}
																	className={({ active }) =>
																		`relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-teal-600 text-white" : "text-gray-900"
																		}`
																	}
																	value={person}
																>
																	{({ selected, active }) => (
																		<>
																			<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
																				{person.name}
																			</span>
																			{selected ? (
																				<span
																					className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-teal-600"
																						}`}
																				>
																					<CheckIcon className="h-5 w-5" aria-hidden="true" />
																				</span>
																			) : null}
																		</>
																	)}
																</Combobox.Option>
															))
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									</div>
								</div>
							</p>
						</div>
					) : (
						<div className="text-base text-dark-gray/80 mt-0.5">
							<div className="text-center mx-auto w-4/5 bg-red-800 text-white p-4 rounded mt-2 mb-10">
								<p>You don't currently have an assigned skipthecall number.</p>
								<p>
									This can happen for a few reasons. <a href="mailto:hello@skipthecall.com" target="_blank" className="text-white-500 underline hover:text-blue-500">Get in touch</a> if you need help!
								</p>
							</div>
						</div>
					)}
					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleRegister)}>
							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Business Name</label>
								<div className="w-full">
									<CommonTextInput controllerName="businessName" controllerLabel="businessName" fieldType="text" />
								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">First Name</label>
								<div className="w-full">
									<CommonTextInput controllerName="firstName" controllerLabel="" fieldType="text" />
								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Last Name</label>
								<div className="w-full">
									<CommonTextInput controllerName="lastName" controllerLabel="" fieldType="text" />
								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Phone Number</label>
								<div className="w-full">
									<CommonTextInput controllerName="mobile" controllerLabel="" fieldType="text" />

								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Email</label>
								<div className="w-full">
									<CommonTextInput controllerName="email" controllerLabel="" fieldType="email" disabled />
								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Auto-reply Text</label>
								<div className="w-full">
									<CommonTextInput controllerName="autoReplyText" controllerLabel="" fieldType="string" />
								</div>
							</div>

							<div className="flex xsm:flex-row flex-col items-center mb-5">
								<label className="text-dark-gray text-base font-semibold xsm:w-40 w-full">Referral link</label>
								<div className="w-full flex">
									<CommonTextInput controllerName="referralLink" controllerLabel="" fieldType="string" disabled />
									<div className="w-16 bg-secondary rounded-r flex justify-center items-center cursor-pointer">
										<MdFileCopy onClick={handleCopy} className="w-5 h-5 text-white" />
									</div>
								</div>
							</div>
							<div className="text-end xsm:pt-4 pt-3">
								<button
									className="text-white py-2 bg-secondary rounded-md px-10 text-lg font-bold border-2 border-secondary hover:bg-white hover:text-secondary transition duration-500"
									type="submit"
								>
									Save
								</button>
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</div>
	);
};

export default AuthPage(Profile);
