import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "types/stateType";
import Toast from "../../components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constant";
import { EmailInput, SignUpUserInput, UserDetailsInput, UserLoginInput } from "../../interfaceTypes";
import localStorage from "../../services/localStorage.service";
import API, { APISuccessResponse } from "../../utils/axios";

export const SignUpAction = createAsyncThunk<APISuccessResponse, SignUpUserInput>(
	"/auth/signup",
	async (arg, thinkAPI) => {
		try {
			const { data } = await API.post<APISuccessResponse, any>("/auth/signup", arg);
			const { status, message } = data;
			console.log({ status, message });
			if (status !== 200) {
				throw Error(message || SOMETHING_WENT_WRONG_MESSAGE);
			}
			localStorage.setItem("tokens", data?.data?.tokens);
			// localStorage.setItem("user", data?.data?.account);
			await thinkAPI.dispatch(fetchUserAction());
			Toast.success(message ?? "You have signup successfully!");
			return thinkAPI.fulfillWithValue(data.data);
		} catch (error: any) {
			console.log({ error });
			Toast.error(error.data.message.error ?? SOMETHING_WENT_WRONG_MESSAGE);
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const LoginAction = createAsyncThunk<APISuccessResponse, UserLoginInput>(
	"/auth/login",
	async (arg, thinkAPI) => {
		try {
			const { data } = await API.post<APISuccessResponse, any>("/auth/login", arg);
			const { status, message } = data;

			if (status !== 200) {
				throw Error(message || SOMETHING_WENT_WRONG_MESSAGE);
			} else {
				localStorage.setItem("tokens", data?.data?.tokens);
				localStorage.setItem("user", data?.data?.account);
				await thinkAPI.dispatch(fetchUserAction());
				Toast.success(message ?? "You have signed in successfully!");
				return thinkAPI.fulfillWithValue(data.data);
			}
		} catch (error: any) {
			Toast.error(error.data.message.error ?? SOMETHING_WENT_WRONG_MESSAGE);
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const UpdateUserAction = createAsyncThunk<APISuccessResponse, UserDetailsInput>(
	"/user",
	async (arg, thinkAPI) => {
		try {
			const { data } = await API.patchForm<APISuccessResponse, any>("/user", arg);
			const { status, message } = data;

			if (status !== 200) {
				throw Error(message || SOMETHING_WENT_WRONG_MESSAGE);
			} else {
				await thinkAPI.dispatch(fetchUserAction());
				Toast.success(message ?? "Details updated successfully");
				return thinkAPI.fulfillWithValue(data.data);
			}
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const ForgotPasswordAction = createAsyncThunk<APISuccessResponse, EmailInput>(
	"auth/forgotPassword",
	async (arg, thinkAPI) => {
		try {
			const { data } = await API.post<APISuccessResponse, any>("auth/forgotPassword", arg);
			const { status, message } = data;

			if (status !== 200) {
				throw Error("No user found with this email" || SOMETHING_WENT_WRONG_MESSAGE);
			}
			Toast.success(message ?? "Check your email for a password reset link!");
			return thinkAPI.fulfillWithValue(data);
		} catch (error: any) {
			Toast.error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			return thinkAPI.rejectWithValue(new Error(error.data.message ?? SOMETHING_WENT_WRONG_MESSAGE));
		}
	},
);

export const ResetPasswordAction = createAsyncThunk<APISuccessResponse, { password: string; token: string }>(
	"auth/resetPassword",
	async (arg, thinkAPI) => {
		try {
			const { data } = await API.post("auth/resetPassword", arg);
			const { status, message } = data;
			if (status !== 200) {
				throw Error("Reset password failed");
			}
			Toast.success(message ?? "Password reset successfully");
			return thinkAPI.fulfillWithValue(data);
		} catch (error: any) {
			Toast.error(error.data.message ?? "Reset password failed");
			return thinkAPI.rejectWithValue(new Error(error.data.message ?? "Reset password failed"));
		}
	},
);

export const fetchUserAction = createAsyncThunk("user/me", async (_, thinkAPI) => {
	console.trace("fetching user");
	try {
		const { data, status } = await API.get<APISuccessResponse<User>>("/user/me");
		localStorage.setItem("user", data.data);
		if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
		console.log("got user", data);
		return thinkAPI.fulfillWithValue(data.data);
	} catch (error: any) {
		if (error.response.data.message)
			return thinkAPI.rejectWithValue(new Error(error.response.data.message || "Please login first"));
		return thinkAPI.rejectWithValue(new Error(error.message || "Please login first"));
	}
});

export const refreshToken = createAsyncThunk("auth/refreshToken", async (_, thinkAPI) => {
	try {
		const tokens = localStorage.getItem("tokens") ?? {};
		const { data, status } = await API.post<APISuccessResponse<User>>(`auth/token/${tokens.refresh_token}`);
		localStorage.setItem("tokens", data);
		if (location.pathname === "payment") {
			location.replace("/");
		}
		if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
		return thinkAPI.fulfillWithValue(data.data);
	} catch (error: any) {
		if (error.response.data.message) return thinkAPI.rejectWithValue(new Error(error.response.data.message));
		return thinkAPI.rejectWithValue(new Error(error.message));
	}
});

export const logoutAction = createAsyncThunk<{}>("user/logout", async (arg, thinkAPI) => {
	localStorage.clear();
	return thinkAPI.fulfillWithValue({});
});
